<template>
  <div>
    <vs-dialog blur prevent-close not-close v-model="active">
      <template #header>
        <h4 class="not-margin mt-3">
          <div class="d-flex justify-content-center ">
            <div class="navbar-brand"><img src="@/assets/logo-nav.png" width="133"></div>
          </div>
        </h4>
      </template>
      <div class="container-fluid">
        <div class="row" v-if="tiersOn">
          <div class="col-md-12">
            <p>Choose the plan that best suits the needs of your company, remember that you can purchase more plans or
              add-ons for the best performance.</p>
          </div>
          <div class="col-md-4">
            <!-- <vs-switch color="#214455" style="background: #1a5cff !important" v-model="billed">
              <template #off>
                Bill monthly
              </template>
              <template #on>
                Bill annually
              </template>
            </vs-switch> -->
            <span class="text-main-gradient-secondary">Billed annually</span>
          </div>
          <div class="col-md-8">
            <p>Would you like to add an <strong class="text-main-gradient-secondary">annual hosting
                plan</strong> to your tier?</p>
            <vs-switch color="#214455" style="background: #1a5cff !important" v-model="hosting">
              <template #off>
                No
              </template>
              <template #on>
                Yes add a $3000 USD/year hosting
              </template>
            </vs-switch>
          </div>
        </div>
        <div class="row" v-if="tiersOn">
          <div class="col-md-4 d-flex mt-3" v-for="(item, index) in tiers" :key="index">
            <div class="card main-card h-100">
              <div class="card-body">
                <p class="text-main-gradient">{{item.package_name}}</p>
                <template v-if="hosting">
                  <h3>${{item.cost_yearly }} <small class="text-muted">/yearly</small></h3>
                </template>
                <template v-else>
                  <h3>${{item.cost_yearly}} <small class="text-muted">/yearly</small></h3>
                </template>
                <hr>
                <p>
                  <strong>Features</strong> <br> <br>
                  <span class="smallText mb-1 d-flex align-items-center justify-content-left"
                    v-for="(item2, index2) in item.features" :key="index2">
                    <div class="circle me-1"></div> {{item2.feature_name}}
                  </span>
                </p>
              </div>
              <div class="card-footer">

                <template v-if="hosting">
                  <vs-button @click="selectTier(item.package_id, item.cost_yearly, item.package_name, 'yearly')"
                    block>Buy {{item.package_name}}</vs-button>
                </template>
                <template v-else>
                  <vs-button @click="selectTier(item.package_id, item.cost_yearly, item.package_name, 'yearly')"
                    block>Buy {{item.package_name}}</vs-button>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-show="selectedOn">
          <div class="col-md-12">
            <h4>Checkout</h4>
            <p>Please check that the data of the tier you have chosen is correct.</p>
            <div class="card main-card">
              <div class="card-body">
                <p>
                  <strong>Tier:</strong>
                  <template v-if="hosting">
                    <span class="float-end">{{selectedTierName}} (${{selectedTierCost}}) + Annual hosting plan
                      ($3000)</span>
                  </template>
                  <template v-else>
                    <span class="float-end">{{selectedTierName}} + Annual hosting plan</span>
                  </template>
                  <br>
                  <strong>Periodicity:</strong> <span class="float-end">{{selectedTierTime}}</span><br>
                  <hr>

                  <template v-if="hosting">
                    <strong>TOTAL:</strong> <span class="float-end">${{selectedTierCost + 3000}} USD</span>
                  </template>
                  <template v-else>
                    <strong>TOTAL:</strong> <span class="float-end">${{selectedTierCost}} USD</span>
                  </template>

                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <p>Card Data</p>
            <div class="card main-card">
              <div class="card-body">
                <div ref="cardElement"></div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5 text-center">
            <vs-button @click="processPayment()" block>Confirm Payment</vs-button>
            <a class="text-link smallText text-center" @click="backToTier()">Return</a>
          </div>


        </div>
        <div class="row" v-show="tierOn">
          <div class="col-md-12">
            <h4>Configuration</h4>
            <p>Let's configure your first tier.</p>
            <p>
              <strong>Tier:</strong> {{loadTier.package_name}} <br>
              <strong>Features: </strong> <br>
              <span v-for="(item, index) in loadTier.features" :key="index">
                -{{item.feature_name}} <br>
              </span>
            </p>
            <div class="card main-card">
              <div class="card-body">
                <div class="form-group">
                  <span class="text-muted mt-3">1.- Select your tier:</span>
                  <select class="form-control" v-model="tierSelected">
                    <option value="0" selected>--Select your tier--</option>
                    <option :value="loadTier.package_id">{{loadTier.package_name}}</option>
                  </select>
                </div>
                <div class="form-group mt-2">
                  <span class="text-muted mt-3">2.- Create a domain name. </span>
                  <input type="text" class="form-control" v-model="farmName" placeholder="E.g. Agtlink main farm">
                  <p class="text-muted">Domain name is the main area where the tier will exist, domain name can be the
                    name of your company or some subdivision of it.</p>
                </div>

                <div class="form-group mt-2">
                  <span class="text-muted mt-3">Storage Path</span>
                  <input type="text" class="form-control mr-1" placeholder="Select a Path" v-model.trim="storage_path"
                    maxlength="500" disabled />
                  <!-- <a href="#" class="text-link smallText me-2" v-on:click="handleFileUpload()">Select Path</a> -->
                  <p class="text-muted">You can change this path within the desktop app</p>

                </div>

              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5 text-center">
            <vs-button @click="continue1()" block>Continue</vs-button>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 text-center">
            <span class="nav-link smallText d-flex align-items-center justify-content-center">Powered by
            </span>
            <img class="align-items-center justify-content-center" src="@/assets/stripe.svg" alt="Stripe Logo"
              width="99">
          </div>
          <div class="col-md-12">
            <a class="nav-link smallText d-flex align-items-center justify-content-center" href="#"
              @click="logoutUser()">
              <span class="material-icons smallText me-1">power_settings_new</span> Logout
            </a>
          </div>
        </div>
      </div>
    </vs-dialog>
    <div class="container-fluid overflow-hidden">
      <div class="row vh-100 remove-vh-100-sm2  overflow-auto">
        <Sidebar />

        <main class="col d-flex flex-column h-sm-100">
          <Navbar />
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  import { loadStripe } from '@stripe/stripe-js';
  import Navbar from '@/components/Navbar.vue';
  import Sidebar from '@/components/Footer.vue';
  import axios from 'axios';
  var config = require("@/config/api.json");
  const API = config.api;
  import { CardElement } from '@stripe/stripe-js';
  import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    EmailAuthProvider,
    updateEmail,
    reauthenticateWithCredential,
    updatePassword,
    updateProfile
  } from "firebase/auth";
  import { auth } from "@/fb";

  export default {
    name: 'Dashaboard',
    components: {
      Navbar, Sidebar
    },
    watch: {
      hosting(value) {
        if (value) {
          this.billTime = "annnually"
          this.tier1 = 600
          this.tier2 = 1200
          this.tier2p = 1600
        } else {
          this.billTime = "annnually"
          this.tier1 = 600
          this.tier2 = 1200
          this.tier2p = 1600
        }
      },
    },
    data() {
      return {
        active: false,
        hosting: false,
        billTime: "annually",
        tier1: 50,
        tier2: 100,
        tier2p: 160,
        tiers: [],
        tiersOn: true,
        selectedOn: false,
        tierOn: false,
        selectedTierId: null,
        selectedTierCost: null,
        selectedTierName: null,
        selectedTierTime: null,
        cardElement: null,
        stripe: null,
        storedUser: null,
        loadTier: [],
        tierSelected: 0,
        farmName: null,
        storage_path: '%USERPROFILE%\\Desktop',
        packageId: null
      };
    },
    async created() {
      try {
        localStorage.removeItem('user');
        await this.checkAuthState();
        const user = auth.currentUser;
        console.log('CREA DASHBOARD:::::::::::::::::::::::::::')

        if (user) {
          this.storedUser = JSON.parse(localStorage.getItem('user'));
          console.log('USUARIO GUARDADO', this.storedUser)
        
          if (this.storedUser.tiers === null) {
            this.active = true

          } else {
            if (parseInt(this.storedUser.tiers.length) == 1) {
              let tier = this.storedUser.tiers[0].status
              let tierId = this.storedUser.tiers[0].id
              this.packageId = this.storedUser.tiers[0].id
              if (tier == 1) {
                this.active = true;
                this.tierOn = true;
                this.tiersOn = false;
                this.selectedOn = false;
                this.getTierById(this.storedUser.tiers[0].tier, tierId);

              }
            }
          }
        }
      } catch (error) {
        console.error("Error en mounted: ", error);
        // Manejar el error, por ejemplo, redirigir al usuario, mostrar un mensaje, etc.
      }


    },
    mounted() {
      console.log(this.$route.path)
      if (this.$route.path !== '/home') {
        setTimeout(() => {
          console.log('redireccion')
          this.$router.push({ name: 'Home' });
        }, 3000);
      }

      this.getTiers();
      setTimeout(() => {
        this.initializeStripe();
      }, 2000);


      // ipcRenderer.on("getRoute", (event, args) => {
      //   if (!args.route[0]) {
      //     return;
      //   } else {
      //     var regex = /\\/g;
      //     var FilePath = args.route[0].replace(regex, "\\\\");
      //     this.storage_path = FilePath;
      //   }
      // });

    },
    methods: {
      async continue1() {
        if (parseInt(this.tierSelected) == 0) {
          this.notify('error', 'Please select yout tier');
          return;
        }
        if (!this.vEmpty(this.farmName, 'Farm Name')) {
          return;
        }

        if (this.farmName.length > 50) {
          this.notify('error', 'Too many characters for a farm name');
          return;
        }

        if (!this.vEmpty(this.storage_path, 'Storage Path')) {
          return;
        }

        if (this.storage_path.length > 255) {
          this.notify('error', 'Storage path is too large');
          return;
        }

        console.log(this.user)

        const newFarm = {
          user_id: this.storedUser.id,
          name: this.farmName,
          storage_path: this.storage_path,
          triedId: this.tierSelected,
          packageId: this.packageId,
          features: JSON.stringify(this.loadTier.features)
        };

        this.openLoading();

        try {
          await axios.post(`${API}/register-farm-tier`, newFarm);
        } catch (error) {
          this.closeLoading();
          this.notify('error', error.response.data.error);
        } finally {
          this.notify('success', 'Tier was registered successfully');
          this.closeLoading();
          setTimeout(() => {
            location.reload();
          }, 2000);
        }
      },
      handleFileUpload() {
        // ipcRenderer.send("select-dirs", true);
      },
      async initializeStripe() {
        this.stripe = await loadStripe('pk_test_4YPnh1YgvCH9ltCRyOYK8Irm');
        const elements = this.stripe.elements();
        this.cardElement = elements.create('card', {
          style: {
            base: {
              fontSize: '16px',
              color: "#0e7e29"
            },
          }
        });
        this.cardElement.mount(this.$refs.cardElement);
      },
      backToTier() {
        this.selectedOn = false;
        this.tierOn = false;
        this.tiersOn = true;
        this.selectedTierId = null;
        this.selectedTierCost = null;
        this.selectedTierName = null;
        this.selectedTierTime = null;
      },
      selectTier(id, cost, name, time) {
        this.selectedTierId = id;
        this.selectedTierCost = cost;
        this.selectedTierName = name;
        this.selectedTierTime = time;
        this.tiersOn = false;
        this.tierOn = false;
        this.selectedOn = true;

      },
      async getTiers(item) {
        this.tiers = []
        await axios.get(`${API}/getTiers`)
          .then((data) => {
            let response = data.data
            if (response.length > 0) {
              this.tiers = response
            }
          })
          .catch(function (error) {
            console.log('entra')
            console.log(error);
          });
      },
      async getTierById(id, packageId) {
        this.loadTier = []
        await axios.get(`${API}/getTierbyId/${id}`)
          .then((data) => {
            let response = data.data
            this.loadTier = response
            this.loadTier.package_id = packageId
            console.log('load TIER')
            console.log(this.loadTier)

          })
          .catch(function (error) {
            console.log(error);
          });
      },
      async processPayment() {
        this.openLoading();
        const { token, error } = await this.stripe.createToken(this.cardElement);
        if (error) {
          // Manejar el error (puede mostrar un mensaje de error al usuario)
          console.error(error.message);
          this.closeLoading();
        } else {
          // Enviar el token al backend
          this.sendTokenToBackend(token);
        }
      },
      async sendTokenToBackend(token) {

        let total = 0;
        let hasHosting = 0;
        let tierDesc = `Payment for ${this.selectedTierTime} ${this.selectedTierName}`


        try {

          if (this.hosting) {
            total = this.selectedTierCost + 3000;
            tierDesc = `Payment for ${this.selectedTierTime} ${this.selectedTierName} + Annual hosting plan`;
            hasHosting = 1;
          } else {
            total = this.selectedTierCost;
            tierDesc = `Payment for ${this.selectedTierTime} ${this.selectedTierName}`;
          }


          const response = await axios.post(`${API}/make-payment`, {
            token: token.id,
            amount: parseInt(total * 100),
            user: this.storedUser,
            tier: tierDesc,
            tierId: this.selectedTierId,
            tierMode: this.selectedTierTime,
            hasHosting: hasHosting,
            hasFarm: 0,
            addon: 5
          });

          // Manejar la respuesta del backend (puede mostrar un mensaje de éxito al usuario)
          console.log(response.data);
          this.cardElement.clear();
          this.closeLoading();
          this.notify('success', 'Successfull Payment');
          this.cardElement.clear();
          setTimeout(() => {
            location.reload()
          }, 3000);
        } catch (error) {
          this.closeLoading();
          this.cardElement.clear();
          // Manejar errores del backend (puede mostrar un mensaje de error al usuario)
          console.error(error.response.data);
        }
      },
      logoutUser() {
        this.logout();
      }
    },
  }
</script>